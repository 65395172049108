import * as Yup from 'yup';
import 'react-phone-number-input/style.css';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography, TextField, Autocomplete, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
import { updateVenueAdvanceDetails } from '../VenuesSlice';
// components
import { FormProvider, RHFSwitch, RHFTextField, RHFSelect, RHFMultiSelect, RHFSwitch0 } from '../../../../components/hook-form';
// hooks
import useLocales from '../../../../hooks/useLocales';
import useResponsive from '../../../../hooks/useResponsive';
// sections
import {
  CornerCutShapeStyle
} from '../styles';
import useAuth from '../../../../hooks/useAuth';
import { useParams } from 'react-router-dom';
import React from "react";
// ----------------------------------------------------------------------

export default function BranchGeneral() {
  const isDesktop = useResponsive('up', 'sm');
  const dispatch = useDispatch();
  const { org_id = '', venue_id = '' } = useParams();
  const theme = useTheme();
  const { Translate, translate: t } = useLocales();
  const { cowork, schedules,currency_code } = useSelector((state) => state.venueOnboardEdit.branch);
  const { coworkInfoOptions, venueId } = useSelector((state) => state.venueOnboardEdit);
  const CURRENCY = currency_code;
  const { user } = useAuth();
  const UpdateBranchSchema = Yup.object().shape({
    reviewBadges: Yup.array(), // .min(1).required(t('venueCreateEdit.error-reviewBadges')),
    wifi_name: Yup.string().required(t('venueCreateEdit.error-wifiName')),
    wifi_password: Yup.string().required(t('venueCreateEdit.error-wifiPassword')),
    capacity: Yup.number().min(0, t('Capacity value must be 0 or more')).required(t('venueCreateEdit.error-capacity')),
    schedule_time_set: Yup.string().required(t('This is required')),
    monthly_price: Yup.number()
        .transform((value, originalValue) =>
            originalValue === '' || originalValue === undefined ? null : value
        )
        .nullable()
        .notRequired()
        .typeError(t('Please enter a number as price'))
        .min(0, t('price value must be 1 or more'))
        .test(
            "maxDigitsAfterDecimal",
            t('price can have max 2 decimals only'),
            (number) => number == null || Number.isInteger(number * (10 ** 2))
        ),

    annually_price: Yup.number()
        .transform((value, originalValue) =>
            originalValue === '' || originalValue === undefined ? null : value
        )
        .nullable()
        .notRequired()
        .typeError(t('Please enter a number as price'))
        .min(0, t('price value must be 1 or more'))
        .test(
            "maxDigitsAfterDecimal",
            t('price can have max 2 decimals only'),
            (number) => number == null || Number.isInteger(number * (10 ** 2))
        ),
  });

  const defaultValues = {
    ...cowork,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateBranchSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {

    try {
      const fieldsToSanitize = ['monthly_price', 'annually_price'];
      fieldsToSanitize.forEach((field) => {
        if (
            data[field] === '' ||
            data[field] === undefined ||
            data[field] === null ||
            isNaN(data[field])
        ) {
          data[field] = null;
        }
      });
      const coworkData = {
        ...data,
        // good_for: data.goodFor,
        review_badges: data?.reviewBadges,
        good_for: data.goodFor.map(drink => drink.id),
        amenities: data.amenities.map(drink => drink.id),
        drinks: data.drinks.map(drink => drink.id),
      };
      delete coworkData.goodFor;
      delete coworkData.reviewBadges;
      console.log('onSubmit', coworkData);
      dispatch(updateVenueAdvanceDetails({ cowork_settings: coworkData }, 'cowork_settings', venueId, org_id));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          {/* <Card sx={{ p: 3 }}> */}
          <Box sx={{ pb: 4 }}>
            <Typography variant="h4" align="left" >
              Coworking options
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
            }}
          >
            {/* <RHFMultiSelect name="goodFor" label={t('venueCreateEdit.form-goodFor')} listItems={coworkInfoOptions.good_for || []} /> */}
            <Controller
              name="goodFor"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  onChange={(event, newValue) => {
                    console.log("onchange", newValue, typeof newValue)
                    field.onChange(newValue)
                  }}
                  options={coworkInfoOptions.good_for?.length ? coworkInfoOptions.good_for : []}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, { id, name }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <Box
                        key={key}
                        component="li"
                        {...optionProps}
                      >
                        {name}
                      </Box>
                    );
                  }}
                  renderInput={(params) => <TextField label={t('venueCreateEdit.form-goodFor')} {...params} />}
                />
              )}
            />
            {/*<RHFMultiSelect name="reviewBadges" label={t('venueCreateEdit.form-reviewBadges')} listItems={coworkInfoOptions?.review_badges ? coworkInfoOptions?.review_badges : []}  {...(cowork.reviewBadges && { disabled: true } || {})} />*/}
            <RHFTextField name="wifi_name" label={t('venueCreateEdit.form-wifiName')} />
            <RHFTextField name="wifi_password" label={t('venueCreateEdit.form-wifiPassword')} />
            <RHFSelect name="ambience" label={t('venueCreateEdit.form-ambiance')} onSelect={(e) => console.log('ambiance selected', e)}>
              <option value="" />
              {coworkInfoOptions?.ambience?.length &&
                coworkInfoOptions?.ambience.map(({value,label}) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
            </RHFSelect>
            <RHFSelect name="schedule_time_set" label={t('Choose a Timing Schedule')}>
              <option value="" />
              {schedules?.length && schedules?.map((option, index) => (
                <option key={`schedules${index}`} value={option.id}>
                  {option.name}
                </option>
              ))}
            </RHFSelect>
            <RHFTextField type="Number" name="capacity" label={t('form.capacity')}/>
            <RHFTextField
                name="monthly_price"
                label={<Translate i18nKey="Monthly Price" currency={CURRENCY}>Monthly Price ({CURRENCY})</Translate>}
            />
            <RHFTextField
                name="annually_price"
                label={<Translate i18nKey="Annually Price" currency={CURRENCY}>Annually Price ({CURRENCY})</Translate>}
            />
            <Controller
              name="amenities"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  onChange={(event, newValue) => {
                    console.log("onchange", newValue, typeof newValue)
                    field.onChange(newValue)
                  }}
                  options={coworkInfoOptions?.amenities?.length ? coworkInfoOptions?.amenities : [] }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, { id, name }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <Box
                        key={key}
                        component="li"
                        {...optionProps}
                      >
                        {name}
                      </Box>
                    );
                  }}
                  renderInput={(params) => <TextField label="Amenities" {...params} />}
                />
              )}
            />
            <Controller
              name="drinks"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  onChange={(event, newValue) => {
                    console.log("onchange", newValue, typeof newValue)
                    // const customVal = newValue.map(val => val.id);
                    field.onChange(newValue)
                  }}
                  options={coworkInfoOptions.freedrinks}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, { id, name, icon }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <Box
                        key={key}
                        component="li"
                        {...optionProps}
                      >
                        {/* <Box sx={{ display: 'flex', alignItems: 'center'}}> */}
                        {/* <Avatar alt={name} src={icon||''} sx={{ width: 30, height: 30, mr: 1, flexShrink: 0 }} /> */}
                        {name}
                        {/* </Box> */}
                      </Box>
                    );
                  }}
                  renderInput={(params) => <TextField label={t('venueCreateEdit.drinks')} {...params} />}
                />
              )}
            />
            {/*<RHFSwitch*/}
            {/*  name="first_free_checkin"*/}
            {/*  label={<Translate i18nKey="Free First Checkin" />}*/}
            {/*/>*/}
          </Box>
          <Stack spacing={3} alignItems="flex-start" sx={{ mt: 3 }}>
            <CornerCutShapeStyle sx={{ mb: 2, position: 'relative' }}>
              <LoadingButton type="submit" size="large" variant="contained" loading={isSubmitting}>
                <Translate i18nKey='global.btn-saveChanges' />
              </LoadingButton>
            </CornerCutShapeStyle>
          </Stack>
          {/* </Card> */}
        </Grid>
      </Grid>
    </FormProvider>
  );
}
