import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import axios from 'src/utils/axios';
import { useForm,useWatch , Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {Box, Grid, Card, Stack, Typography, Container, TextField, Autocomplete} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'src/redux/store';
import { FormProvider, RHFTextField, RHFSelect } from 'src/components/hook-form';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import PhoneInputWrapper from 'src/components/PhoneInputWrapper';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSnackbar } from 'notistack';
import { URL_PLACES } from 'src/utils/restApiUrls';
import useAuth from 'src/hooks/useAuth';
import {useNavigate} from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import RHFAutocomplete from "src/components/hook-form/RHFAutocomplete";
import {ROLE} from "src/utils/constants";
import {getOrganisationsList} from "src/pages/dashboard/organisation/OrganisationSlice";
import {getBulkEmailSupportOptions, getVenues} from "../VenuesSlice";
import RHFMultiSelect from "../../../../components/hook-form/RHFMultiSelect";
import {capitalization} from "../../../../utils/helpers";

Yup.addMethod(Yup.string, 'validatePhone', function (errorMessage) {
    return this.test('validate-phone-number', errorMessage, (value) => {
        return isValidPhoneNumber(value) || this.createError({ message: errorMessage });
    });
});

const PH_REGEX = /^\+92[0-9]{10}$/;
const COORDINATE_REGEX = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,16}/;
const WEB_URL_REGEX = /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/;

const SendBulkEmailNotifications = () => {
    const isDesktop = useResponsive('up', 'sm');
    const {themeStretch} = useSettings();
    const {user} = useAuth();
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const {Translate, translate: t} = useLocales();
    const {enqueueSnackbar} = useSnackbar();
    const {organisationsList} = useSelector((state) => state.orginization);
    const {emailTypes,replyTo,venues,isLoading} = useSelector((state) => state.venueOnboardEdit);
    const UpdateBranchSchema = Yup.object().shape({
        venue_ids: Yup.array().notRequired(),
        subject: Yup.string().required('Subject is required'),
        campaign: Yup.string().required('Campaign is required'),
        bulk_email_type: Yup.string().required('Email type is required'),
        reply_to: Yup.string().required('Reply to is required'),
    });
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const methods = useForm({
        resolver: yupResolver(UpdateBranchSchema),
        defaultValues: {
            venue_ids: [],
            subject: '',
            campaign: '',
            bulk_email_type: '',
            reply_to: '',
        },
    });

    const {
        handleSubmit,
        reset,
        control,
        formState: {isSubmitting},
    } = methods;

    const venueIds = useWatch({
        control,
        name: 'venue_ids',
    });
    const hasVenues = Array.isArray(venueIds) && venueIds.length > 0;

    const onSubmit = async (formData) => {
        try {
            console.log(formData,"formData")
            const contentValue = editorState.getCurrentContent().getPlainText();
            console.log(contentValue,"contentValue")
            if (!contentValue.trim()) {
                // If the content is empty, set an error message
                enqueueSnackbar("Content is required", {variant: 'error', preventDuplicate: false});
                return; // Prevent form submission
            }

            const buildEmailPayload = (formData) => ({
                subject: formData.subject,
                message: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                campaign: formData.campaign,
                venue_ids:  Array.isArray(formData.venue_ids)
                    ? formData.venue_ids.map((v) => (typeof v === 'object' && v?.id ? v.id : v))
                    : [],
                bulk_email_type: formData.bulk_email_type,
                reply_to: formData.reply_to,
            });

            const response = await axios.post(
                URL_PLACES.emailNotification,
                buildEmailPayload(formData)
            );
            const {success, message} = response.data;
            if (success) {
                enqueueSnackbar(message, {preventDuplicate: false});
                setEditorState(EditorState.createEmpty())
                reset();
            }
        } catch (error) {
            console.log(error,"submit data")
            const errorData = error?.response?.data?.error;
            const message = error?.response?.data?.message;
            let combinedMessage = '';

            // Iterate through each key in the error data
            for (const key in errorData) {
                if (errorData.hasOwnProperty(key)) {
                    const value = errorData[key];

                    // Check if the value is an array
                    if (Array.isArray(value)) {
                        combinedMessage += `${key}: ${value.join(', ')} `;
                    } else {
                        combinedMessage += `${key}: ${value} `;
                    }
                }
            }
            enqueueSnackbar(message + " " + combinedMessage, {variant: 'error', preventDuplicate: false});
        }
    };


    useEffect(() => {

        if(user?.system_user === ROLE.SUPER_USER || user?.system_user === ROLE.CUSTOMER_SUPPORT){
            if(venues?.length === 0){
                dispatch(getVenues());
            }

        }

        dispatch(getBulkEmailSupportOptions());

    }, []);


    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState)
        const contentState = newEditorState.getCurrentContent();
        const plainText = contentState.getPlainText();

    }



    return (
        <Page title={t('global.venueSettings')}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading='Email Notifications'
                    links={[
                        {name: 'Venues', href: PATH_DASHBOARD.venues.list},
                        {name: 'Send Bulk Email Notifications'},
                    ]}
                />
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={10}>
                            <Card sx={{p: 3}}>
                                {!hasVenues ? ( <Box sx={{mb: 3}}>
                                    <Typography variant="subtitle" sx={{color: "#919EAB"}}>
                                        Note: You're seeing this message because you haven't selected any venues. This means the email will be sent to all venues.
                                        If you want to target specific venues only, please select them from the "Select Venue" dropdown below.
                                    </Typography>
                                </Box>):null}
                                <Box
                                    sx={{
                                        display: 'grid',
                                        rowGap: 3,
                                        columnGap: 1,
                                        marginBottom:"24px",
                                        gridTemplateColumns: {xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)'},
                                    }}
                                >


                                    {/*<RHFAutocomplete name="venues_id" label="Select Venue" sx={{mb: 2}}*/}
                                    {/*                 options={venues.length ? venues.map((item) => ({*/}
                                    {/*                     id: item.id,*/}
                                    {/*                     label: item.name || item.id*/}
                                    {/*                 })) : []} getOptionLabel={(option) => option.label}/>*/}

                                    {/*<RHFMultiSelect name="venue_ids" label={"Select Venue"}*/}
                                    {/*                listItems={venues.length ? venues : []}*/}
                                    {/*/>*/}
                                    <Controller
                                        name="venue_ids"
                                        control={control}
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                multiple
                                                onChange={(event, newValue) => field.onChange(newValue)}
                                                options={venues.length  ? venues : []}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                autoHighlight
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(props, { id, name }) => (
                                                    <Box key={id} component="li" {...props}>
                                                        {name}
                                                    </Box>
                                                )}
                                                renderInput={(params) => <TextField label="Select Venue" {...params} />}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={"subject"}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField {...field} fullWidth error={!!error} helperText={error?.message} label={t('global.subject')}   autoComplete="off"/>
                                        )}
                                    />

                                </Box>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                    toolbar={{
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                    }}
                                />

                                <Box
                                    sx={{
                                        display: 'grid',
                                        rowGap: 3,
                                        columnGap: 1,
                                        marginTop:"24px",
                                        gridTemplateColumns: {xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)'},
                                    }}
                                >

                                    <Controller
                                        name={"campaign"}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField {...field} fullWidth error={!!error} helperText={error?.message}  label={t('global.campaign')}  autoComplete="off" />
                                        )}
                                    />
                                    <RHFSelect name='bulk_email_type' label='Email Type'>
                                        <option value=""/>
                                        {emailTypes?.length ?
                                            emailTypes?.map((value) => <option key={value} value={value}>{capitalization(value)}</option>)
                                            : null}
                                    </RHFSelect>
                                    <RHFSelect name='reply_to' label='Reply To'>
                                        <option value=""/>
                                        {replyTo?.length ?
                                            replyTo?.map((value) => <option key={value} value={value}>{capitalization(value)}</option>)
                                            : null}
                                    </RHFSelect>
                                </Box>
                                <Stack spacing={3} alignItems='flex-end' sx={{mt: 3}}>
                                    <LoadingButton type='submit' variant='contained' loading={isSubmitting}>
                                        <Translate i18nKey='global.btn-send'/>
                                    </LoadingButton>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>


                </FormProvider>
            </Container>
        </Page>
    );
};

export default SendBulkEmailNotifications;