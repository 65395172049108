import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { fDisplay } from '../../../utils/formatTime';
import { URL_PLACES } from '../../../utils/restApiUrls';
//
import { dispatch } from '../../../redux/store';

// ----------------------------------------------------------------------
const DETAILS_FETCH_ERROR = 'roomCreateEdit.error.fetchSpace';
const B_DETAILS_FETCH_ERROR = 'roomCreateEdit.error.fetchBranch';
const A_DETAILS_FETCH_ERROR = 'roomCreateEdit.error.fetchAmenity';
const SETUP_DETAILS_FETCH_ERROR = 'roomCreateEdit.error.fetchSetup';
const CREATE_ERROR = 'Failed to create new space';
const CREATE_SUCCESS = 'Space created!';
const UPDATE_ERROR = 'roomCreateEdit.error.save';
const UPDATE_SUCCESS = 'roomCreateEdit.success.save';
const DDR_DELETE_ERROR = 'Failed to delete package!';
const DDR_DELETE_SUCCESS = 'Deleted!';
const M_UPLOAD_SUCCESS = 'roomCreateEdit.success.uploadImage';
const M_UPLOAD_ERROR = 'roomCreateEdit.error.uploadImage';
const M_DELETE_SUCCESS = 'roomCreateEdit.success.deleteImage';
const M_DELETE_ERROR = 'roomCreateEdit.error.deleteImage';
const M_ISFRONT_SUCCESS = 'roomCreateEdit.success.setIsFrontImage';
const M_ISFRONT_ERROR = 'roomCreateEdit.error.setIsFrontImage';
const SUPPORT_DETAILS_FETCH_ERROR = 'Failed to fetch space onboarding options';
const DEFAULTS = {
  ddr_package: {
    is_ddr_package: false,
    half_day_hours_for_ddr_package: 1,
    full_day_hours_for_ddr_package: 1,
    packages: [],
  },
};
const DEFAULT_START_TIME = '09:00';
const DEFAULT_END_TIME = '18:00';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  isUploading: false,
  error: null,
  alertError: null,
  alertSuccess: null,
  branchid: null,
  branchDetails: null,
  spaceId: null,
  spaceDetails: null,
  amenityOptions: null,
  setupOptions: null,
  isSpaceUpdated: false,
  dateRange: null,
  branch: {
    name: null,
    spaceDesc: null,
    currency: null,
    schedules: {},
  },
  space: {
    general: null,
    images: [],
    amenities: [],
    types: [],
    currency: null,
    setupList: [],
    setups: [],
    setupCapacity: {},
    timings: {},
    ddrInfo: null,
  },
};

const slice = createSlice({
  name: 'spaceOnboardEdit',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START UPDATING
    startUpdating(state) {
      state.isSpaceUpdated = true;
    },

    // SET MEDIA UPLOADING
    setMediaUploadLoading(state,action) {
      state.isUploading = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Display Alert Success
    setAlertSuccess(state, action) {
      const message = action.payload;
      state.alertSuccess = message;
    },

    // Display Alert Error
    setAlertError(state, action) {
      const message = action.payload;
      state.alertError = message;
    },

    // Reset Alert Success
    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    // Reset Alert Error
    resetAlertError(state) {
      state.alertError = null;
    },

    // GET BRANCH DETAILS
    getBranchDetailsSuccess(state, action) {
      // const res = action.payload.data ? action.payload.data : action.payload;
      state.branchDetails = action.payload;
    },

    // GET AMENITY OPTIONS
    getSetupOptionsSuccess(state, action) {
      state.setupOptions = action.payload.data ? action.payload.data : action.payload;
    },
    // GET DateRange
    getDateRangeSuccess(state, action) {
      state.dateRange = action.payload.data ? action.payload.data : action.payload;
    },

    // GET AMENITY OPTIONS
    getSupportOptionsSuccess(state, action) {
      const data = action.payload.data ? action.payload.data : action.payload;
      state.amenityOptions = data?.amenities;
      state.space.types = data?.space_types;
      if(data?.space_setups?.length){
        state.setupOptions = data?.space_setups
        // const setupCapacity = data?.space_setups?.length ? data?.space_setups?.reduce((acc, {value}) => {
        //   acc[`setup${value}`] = 1; // Assign value 1 to each name
        //   return acc;
        // }, {}) : [];
        // state.space.setupCapacity = setupCapacity
      }else{
        state.setupOptions = []
      }
    },

    // GET AMENITY OPTIONS
    getAmenityOptionsSuccess(state, action) {
      state.amenityOptions = action.payload.data ? action.payload.data : action.payload;
    },

    // GET SPACE DETAILS
    getSpaceDetailsSuccess(state, action) {
      // state.isLoading = false;
      state.spaceDetails = action.payload;
    },

    // SET DEFAULTS
    setBranchFormDefaults(state, action) {
      const details = action.payload && action.payload.data ? action.payload.data : action.payload;
      const branchDesc = details?.general?.description || '';
      const currency_code = details?.general?.currency_code || 'AED';
      const branchid = details?.general?.id || 0;

      state.branchid = branchid;
      state.branch.name = details?.general?.name || '';;
      state.branch.spaceDesc = branchDesc;
      state.branch.currency_code = currency_code;
      state.branch.schedules = details?.schedules;
    },

    // SET DEFAULTS
    setSpaceFormDefaults(state, action) {
      const details = action.payload && action.payload.data ? action.payload.data : action.payload;

      const spaceTypeMap = {
        'Meeting Room': 1,
        Office: 2,
        Creative: 3,
      };

      if (!state.space) {
        state.space = {};
      }

      const general = {
        name: details?.general?.name || '',
        space_type: details?.general?.space_type || '',
        total_capacity: details?.general?.total_capacity || '',
        currency: details?.general?.currency || 'AED', // NEW
        location_within_property: details?.general?.location_within_property || '',
        area_sqft: details?.general?.area_sqft || '',
        cancellation_before_hours: details?.general?.cancellation_before_hours ? details?.general?.cancellation_before_hours : 24,
        price_per_hour: details?.general?.price_per_hour,
        visibility: details?.general?.visibility,
        advance_accept_booking_hours: details ? details.general?.advance_accept_booking_hours || 0 : 24,
        // advance_allowed_booking_days: details ? details.general?.advance_allowed_booking_days || 0 : 30,
        additional_info: details?.general?.additional_info || '',
        price_per_day: details?.general?.price_per_day ,
        price_per_month: details?.general?.price_per_month ,
        price_per_year: details?.general?.price_per_year ,
        price_per_half_day: details?.general?.price_per_half_day || 0,
        advance_allowed_booking_days: details?.general?.advance_allowed_booking_days || 365,
        description: details?.general?.description || state.branch?.spaceDesc || '',
        schedule_time_set: details?.general?.schedule_time_set?.id || '',
        // setup_type: details?.setup_type?.map(({name, capacity, seating_arrangement}) => { return {name, capacity, seating_arrangement};}) || [],
        // bookingStartDate: details?.booking_allowed_start_date && toYYMMDD(details.booking_allowed_start_date),
        // bookingEndDate: details?.booking_allowed_end_date && toYYMMDD(details.booking_allowed_end_date),
      };

      const amenities = details?.general?.amenities || [];
      // const amenities = details?.amenities?.map((x) => x.id) || [];


      // const setupList = details?.setup_type?.map(({name, capacity, seating_arrangement}) => { return {name, capacity, seating_arrangement};}) || [],
      const setupCapacity = state?.setupOptions?.length ? state?.setupOptions?.reduce((acc, {value}) => {
        acc[`setup${value}`] = 1; // Assign value 1 to each name
        return acc;
      }, {}) : [];

      const setupList =
        details?.general?.space_setup?.length ? details?.general?.space_setup?.map((x) => {
          setupCapacity[`setup${x?.seating_arrangement}`] = x?.capacity || 1;
          return x;
        }) : [];


      const images = details?.media || [];

      const IS_DDR_EXISTING = details && (details?.general?.is_ddr_package || details.ddr_details && Object.keys(details.ddr_details.packages)?.length);
      const ddrInfo =
        IS_DDR_EXISTING && {
          is_ddr_package: details?.ddr_details?.is_ddr_package || false,
          half_day_hours_for_ddr_package: details?.ddr_details?.half_day_hours_for_ddr_package,
          full_day_hours_for_ddr_package: details?.ddr_details?.full_day_hours_for_ddr_package,
          packages: details?.ddr_details?.packages?.length ? details?.ddr_details?.packages: [],
        } || DEFAULTS.ddr_package;

      state.spaceId = details?.general?.id || 0;
      state.space.currency = details?.currency || state.branch.currency;
      state.space.general = general;
      state.space.images = images;
      state.space.amenities = amenities;
      state.space.setupList = setupList;
      state.space.setups = details?.setups;
      state.space.setupCapacity = setupCapacity;
      // state.space.timings = timings;
      state.space.ddrInfo = ddrInfo;
      state.isLoading = false;
    },

    // CREATE BRANCH DETAILS
    createEditSpaceSuccess(state, action) {
      // state.branch.isUpdated = true;
      const details = action.payload && action.payload.data ? action.payload.data : action.payload;
      state.spaceId = details?.general?.id || 0;
    },

    removeMediaSuccess(state, action) {
      const imageId = action.payload;
      const { space } = state;
      // const { images } = space;
      const filteredItems = state.space.images?.filter((_file) => _file.id !== imageId);

      space.images = filteredItems;
      state.space = space;
    },

    updateMediaSuccess(state) {
      // const imageId = action.payload;
      const imageUpdatedIndex = -1;
      // const filteredItems = state.space.images?.filter((_file, index) => {
      //   const result = _file.id !== imageId;
      //   if (!result) {
      //     imageUpdatedIndex = index;
      //   }
      //   return result;
      // });
      if (imageUpdatedIndex > -1) state.space.images[imageUpdatedIndex].is_front = 1;
    },

    // BRANCH UPDATED - REFETCHed DETAILS
    spaceUpdateSuccess(state) {
      state.isSpaceUpdated = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setSpaceFormDefaults, resetAlertSuccess, resetAlertError } = slice.actions;



// ----------------------------------------------------------------------

export function refreshSpaceDetails(venueId, spaceId, org_id) {
  return async () => {
    const API_URL = `${(`${URL_PLACES.space}`).replace(":org_id", org_id).replace(':venue_id', venueId)}${spaceId?`${spaceId}/`:''}`;
    try {
      const response = await axios.get(API_URL);
      if (response.data.success) {
        const getData = response.data.data;
        dispatch(slice.actions.getSpaceDetailsSuccess(getData));
        dispatch(slice.actions.setSpaceFormDefaults(getData));
        dispatch(slice.actions.spaceUpdateSuccess());
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || DETAILS_FETCH_ERROR)
        );
      }
    } catch (error) {
      console.log('refreshSpaceDetails', error);
      dispatch(slice.actions.hasError(DETAILS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(DETAILS_FETCH_ERROR));
    }
  };
}



// ----------------------------------------------------------------------

export function getBranchDetails(venueId, org_id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_PLACES.venueDetails.replace(":org_id", org_id).replace(':venue_id', venueId));
      if (response.data.success) {
        const getData = response.data.data;
        dispatch(slice.actions.getBranchDetailsSuccess(getData));
        dispatch(slice.actions.setBranchFormDefaults(getData));
      } else {
        dispatch(slice.actions.hasError(response.data.error || B_DETAILS_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error || B_DETAILS_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(B_DETAILS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(B_DETAILS_FETCH_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function getSpaceDetails(venueId, spaceId, org_id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const API_URL = `${(`${URL_PLACES.space}`).replace(":org_id", org_id).replace(':venue_id', venueId)}${spaceId?`${spaceId}/`:''}`;
    try {
      const response = await axios.get(API_URL);
      if (response.data.success) {
        const getData = response.data.data;
        dispatch(slice.actions.getSpaceDetailsSuccess(getData));
        dispatch(slice.actions.setSpaceFormDefaults(getData));
      } else {
        dispatch(slice.actions.hasError(response.data.error || DETAILS_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error || DETAILS_FETCH_ERROR));
      }
    } catch (error) {
      console.log('getSpaceDetails', error);
      dispatch(slice.actions.hasError(DETAILS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(DETAILS_FETCH_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function getSupportOptions(venueId, spaceId) {
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.get(URL_PLACES.supportOptions, {
        params: { venue_id: venueId, space_id: spaceId },
      });
      if (response.data.success) {
        dispatch(slice.actions.getSupportOptionsSuccess(response.data.data));
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error || SUPPORT_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(SUPPORT_DETAILS_FETCH_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function createEditSpaceOnboard2(details, branchid, spaceid) {
  return async () => {
    const API_URL = `${(`${URL_PLACES.space}`).replace(':venue_id', branchid)}${spaceid?`${spaceid}/`:''}`;
    try {
      const response = await axios.post(API_URL, details);
      if (response.data.success) {
        dispatch(slice.actions.createEditSpaceSuccess(response.data.data));
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(CREATE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || CREATE_ERROR)
        );
      }
    } catch (error) {
      console.log('createEditSpace', error);
      dispatch(slice.actions.setAlertError(UPDATE_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function createEditSpaceOnboard(details, branchid, spaceid, org_id,setLoading) {
  return async () => {
    const API_URL = `${(`${URL_PLACES.space}`).replace(":org_id", org_id).replace(':venue_id', branchid)}${spaceid?`${spaceid}/`:''}`;
    try {
      const response = await (spaceid
      ? axios.put(API_URL, details)
      : axios.post(API_URL, details));
      // const response ={ data: { status: 1, context: { venue_id: 591} }} ;
      if (response.data.success) {
        if (response.data.data?.general?.id || !details.id) {
          dispatch(slice.actions.createEditSpaceSuccess(response.data.data));
          dispatch(slice.actions.setAlertSuccess(UPDATE_SUCCESS));
          dispatch(slice.actions.startUpdating());
        } else {
          dispatch(slice.actions.setAlertSuccess(CREATE_SUCCESS));
          dispatch(slice.actions.startUpdating());
        }


      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || UPDATE_ERROR) // // TODO: to remove error.Error.
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(UPDATE_ERROR));
    }finally {
      if(setLoading){
        setLoading(false)
      }
    }
  };
}

// ----------------------------------------------------------------------

export function toggleSpaceMode( org_id, branchid, spaceid,) {
  return async () => {
    const API_URL = `${(`${URL_PLACES.toggleSpaceActiveInactive}`).replace(":org_id", org_id).replace(':venue_id', branchid).replace(':space_id', spaceid)}`;
    try {
      const response = await axios.post(API_URL);
      if (response.data.success) {
        if (response.data.data?.general?.id) {
          dispatch(slice.actions.createEditSpaceSuccess(response.data.data));
          dispatch(slice.actions.setAlertSuccess(UPDATE_SUCCESS));
          dispatch(slice.actions.startUpdating());
        } else {
          dispatch(slice.actions.setAlertSuccess(CREATE_SUCCESS));
          dispatch(slice.actions.startUpdating());
        }


      } else {
        dispatch(
            slice.actions.setAlertError(response.data.error || UPDATE_ERROR) // // TODO: to remove error.Error.
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(UPDATE_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadMedia(file, venueId, spaceId, org_id,onProgress) {
  return async () => {
    try {
      dispatch(slice.actions.setMediaUploadLoading(true))
      const formData = new FormData();
      formData.append('image', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        params: {
          // space_id: spaceId,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgress(progress);  // Update progress
        },
      };
      const response = await axios.post(URL_PLACES.media.replace(":org_id", org_id).replace(":venue_id",venueId).replace(":space_id",spaceId), formData, config);
      if (response.data.success) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_UPLOAD_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || M_UPLOAD_ERROR)
        );
      }
    } catch (error) {
      console.log('uploadMedia', error);
      dispatch(slice.actions.setAlertError(M_UPLOAD_ERROR));
    }finally {
      dispatch(slice.actions.setMediaUploadLoading(false))
    }
  };
}
// ----------------------------------------------------------------------

export function removeMedia(id, venueId, spaceId, org_id) {
  return async () => {
    try {
      const response = await axios.delete(`${URL_PLACES.media.replace(":org_id", org_id).replace(":venue_id",venueId).replace(":space_id",spaceId)}${id}/`, { params: { image_id: id, is_delete: 1, space_id: spaceId } });
      if (response.status==204) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_DELETE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || M_DELETE_ERROR)
        );
      }
    } catch (error) {
      console.log('removeMedia', error);
      dispatch(slice.actions.setAlertError(M_DELETE_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function toggleIsFrontMedia(id, venueId, spaceId, org_id) {
  return async () => {
    try {
      const putData = { is_front: true };
      const response = await axios.put(`${URL_PLACES.media.replace(":org_id", org_id).replace(":space_id",spaceId)}${id}/`,putData, {
        params: { image_id: id, space_id: spaceId },
      });
      if (response.data.success) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_ISFRONT_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || M_ISFRONT_ERROR)
        );
      }
    } catch (error) {
      console.log('toggleIsFrontMedia', error);
      dispatch(slice.actions.setAlertError(M_ISFRONT_ERROR));
    }
  };
}

export function ddrPackage(data, venueId, spaceId) {
  return async () => {
    try {
      const response = await axios.post(`${URL_PLACES.ddrPackage}${spaceId}/`, data, {
        params: { branch_id: venueId, space_id: spaceId },
      });
      if (response.data.success) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(UPDATE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || UPDATE_ERROR)
        );
      }
    } catch (error) {
      console.log('ddrPackage', error);
      dispatch(slice.actions.setAlertError(UPDATE_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function getDateRangeBooking(
  spaceid,
  startDate,
  endDate,
  ddrPackage = null
) {

  return async () => {
    // store.dispatch(slice.actions.resetErrors());
    // store.dispatch(slice.actions.startDateRangeLoading());
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        URL_PLACES.dateRangeBooking,
        {
          params: {
            space_id: spaceid,
            start: startDate,
            end: endDate,
            ...(ddrPackage && { ddr_id: ddrPackage.ddr_id }),
            ...(ddrPackage && { ddr_type: ddrPackage.ddr_type }),
          },
        }
      );
      if (response.data.success) {
        const { data } = response;
        dispatch(slice.actions.getDateRangeSuccess(data));
      } else {
        // store.dispatch(
        //   slice.actions.hasError(
        //     response.data.error?.message ||
        //       response.data.error ||
        //       SPACE_FETCH_ERROR // TODO: change error
        //   )
        // );
        // store.dispatch(slice.actions.stopDateRangeLoading());
      }
    } catch (error) {
      // store.dispatch(slice.actions.hasError(SPACE_FETCH_ERROR)); // TODO: change error
      // store.dispatch(slice.actions.stopDateRangeLoading());
    }
  };
}

// ----------------------------------------------------------------------

export function ddrPackageRemove(index, venueId, spaceId, data, org_id) {
  return async () => {
    const API_URL = `${(`${URL_PLACES.space}`).replace(":org_id", org_id).replace(':venue_id', venueId)}${spaceId?`${spaceId}/`:''}`;
    try {
      console.log(data,"data")
      const response = await axios.delete(API_URL, {
       data
      });
      if(response.status === 204){
        dispatch(slice.actions.startUpdating());
        const getData = response.data.data;
        dispatch(slice.actions.getSpaceDetailsSuccess(getData));
        dispatch(slice.actions.setAlertSuccess(DDR_DELETE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || DDR_DELETE_ERROR)
        );
      }
    } catch (error) {
      console.log('ddrPackageRemove', error);
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}

// ----------------------------------------------------------------------
//              helper
// ----------------------------------------------------------------------

export function toHHmm(dateString) {
  // dateString expected format: 06:00PM
  const newDate = new Date(`2020/01/01 ${dateString.slice(0, 5)} ${dateString.slice(5, 7)}`);
  return fDisplay(newDate, 'HH:mm');
}


export function toYYMMDD(dateString) {
  const dateStr = dateString;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

