// ----------------------------------------------------------------------
// Page viewed
// ----------------------------------------------------------------------
export function TRACK_PAGE_VIEWED() {
  window.analytics?.page();
};

// ----------------------------------------------------------------------
// Signed up
// ----------------------------------------------------------------------
export function TRACK_SIGNUP(user) {
  const params = TRACK_USER(user, true); // TRACK_USER(user, noSession:true);
  window.analytics.track('Signed up', params);
};

// ----------------------------------------------------------------------
// Signed in
// ----------------------------------------------------------------------
export function TRACK_LOGIN(user) {
  const params = TRACK_USER(user);
  window.analytics.track('Signed in', params);
};

// ----------------------------------------------------------------------
// User session
// ----------------------------------------------------------------------
export function TRACK_USER(user, noSession) {
  if(!user) return;
  const params = {
    id: user.user_id,
    name: user.name,
    source: user.source?capitalize(user.source):"Manual",
    user_type: user?.user_type,
    email: user.email
  };
  if(!noSession)
    // window.analytics.identify(user.user_id,params);

  return params;
};

// ----------------------------------------------------------------------
// Signed out
// ----------------------------------------------------------------------
export function TRACK_LOGOUT(user) {
  window.analytics.track('Signed out', {id: user?.user_id || '', username: user?.name || ''});
  window.analytics.reset();
};


// ----------------------------------------------------------------------
//                                HELPER METHODS
// ----------------------------------------------------------------------

export function capitalize(word){
    const output = word.charAt(0).toUpperCase() + word.slice(1);
    return output;
}
