import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { URL_PLACES } from '../../../utils/restApiUrls';
//
import { dispatch } from '../../../redux/store';
import {CALENDER_TYPE} from "../../../utils/constants";


// ----------------------------------------------------------------------
const B_DETAILS_FETCH_ERROR = 'venueCreateEdit.error.fetchbranchDetails';
const B_DETAILS_LOAD_ERROR = 'venueCreateEdit.error.loadBranchDetails';
const F_DETAILS_FETCH_ERROR = 'venueCreateEdit.error.fetchFacilityDetails';
const SUPPORT_DETAILS_FETCH_ERROR = 'Failed to fetch onboarding options';
const C_DETAILS_FETCH_ERROR = 'venueCreateEdit.error.fetchCoworkOptionDetails';
const CON_DETAILS_FETCH_ERROR = 'venueCreateEdit.error.fetchCountryList';
const CITY_DETAILS_FETCH_ERROR = 'venueCreateEdit.error.fetchCityList';
const COUNTRY_LIST_FETCH_ERROR = 'venueCreateEdit.error.fetchCountryList';
const CITY_LIST_FETCH_ERROR = 'venueCreateEdit.error.fetchCityList';
const CREATE_EDIT_ERROR = 'venueCreateEdit.error.save';
const M_UPLOAD_SUCCESS = 'venueCreateEdit.success.uploadImage';
const M_UPLOAD_ERROR = 'venueCreateEdit.error.uploadImage';
const M_DELETE_SUCCESS = 'venueCreateEdit.success.deleteImage';
const M_DELETE_ERROR = 'venueCreateEdit.error.deleteImage';
const M_ISFRONT_SUCCESS = 'venueCreateEdit.success.setIsFrontImage';
const M_ISFRONT_ERROR = 'venueCreateEdit.error.setIsFrontImage';
const F_SET_SUCCESS = 'venueCreateEdit.success.addFacility';
const F_SET_ERROR = 'venueCreateEdit.error.addFacility';
const F_REMOVE__SUCCESS = 'venueCreateEdit.success.removeFacility';
const F_REMOVE__ERROR = 'venueCreateEdit.error.removeFacility';
const C_UPDATE_SUCCESS = 'venueCreateEdit.success.updateCoworkDetails';
const C_UPDATE_ERROR = 'venueCreateEdit.error.updateCoworkDetails';
const D_UPDATE_SUCCESS = 'venueCreateEdit.success.updateDrink';
const D_UPDATE_ERROR = 'venueCreateEdit.error.updateDrink';
const E_UPDATE__SUCCESS = 'venueCreateEdit.success.updateEmails';
const E_UPDATE__ERROR = 'venueCreateEdit.error.updateEmails';
const T_UPDATE__SUCCESS = 'venueCreateEdit.success.updateTimings';
const T_UPDATE__ERROR = 'venueCreateEdit.error.updateTimings';
const P_UPDATE__SUCCESS = 'venueCreateEdit.success.updatePolicies';
const P_UPDATE__ERROR = 'venueCreateEdit.error.updatePolicies';
const S_DELETE__SUCCESS = "Schedule deleted";
const S_DELETE__ERROR = "Failed to delete schedule";
const B_FETCH_ERROR = 'venues.error.fetchBranches';
const CALENDAR_FETCH_ERROR = 'venues.error.fetchCalendars';
const CALENDAR_LINK_SUCCESS = 'venues.success.linkCalendar';
const CALENDAR_LINK_ERROR = 'venues.error.linkCalendar';
// ----------------------------------------------------------------------
// const DEFAULT_TIMING_LIST =  [{"is_closed":false,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Monday"},{"is_closed":false,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Tuesday"},{"is_closed":false,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Wednesday"},{"is_closed":false,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Thursday"},{"is_closed":true,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Friday"},{"is_closed":true,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Saturday"},{"is_closed":true,"start_time":"09:00:00","end_time":"23:00:00","weekday":"Sunday"}];

// const TIMING_SELECTION_INIT = [{"day":1,"weekday":"Monday","start_time":"09:00","end_time":"18:00","hours":"12","is_closed":false,"is_open":true},{"day":2,"weekday":"Tuesday","start_time":"09:00","end_time":"18:00","hours":"12","is_closed":false,"is_open":true},{"day":3,"weekday":"Wednesday","start_time":"09:00","end_time":"18:00","hours":"12","is_closed":false,"is_open":true},{"day":4,"weekday":"Thursday","start_time":"09:00","end_time":"18:00","hours":"12","is_closed":false,"is_open":true},{"day":5,"weekday":"Friday","start_time":"09:00","end_time":"18:00","hours":"12","is_closed":true,"is_open":false},{"day":6,"weekday":"Saturday","start_time":"09:00","end_time":"18:00","hours":"12","is_closed":false,"is_open":true},{"day":7,"weekday":"Sunday","start_time":"09:00","end_time":"18:00","hours":"12","is_closed":false,"is_open":true}];
const DEFAULT_TIMING_LIST = [{ "day": 1, "weekday": "Monday", "start_time": "09:00", "end_time": "18:00", "hours": "12", "is_closed": false, "is_open": true }, { "day": 2, "weekday": "Tuesday", "start_time": "09:00", "end_time": "18:00", "hours": "12", "is_closed": false, "is_open": true }, { "day": 3, "weekday": "Wednesday", "start_time": "09:00", "end_time": "18:00", "hours": "12", "is_closed": false, "is_open": true }, { "day": 4, "weekday": "Thursday", "start_time": "09:00", "end_time": "18:00", "hours": "12", "is_closed": false, "is_open": true }, { "day": 5, "weekday": "Friday", "start_time": "09:00", "end_time": "18:00", "hours": "12", "is_closed": false, "is_open": true }, { "day": 6, "weekday": "Saturday", "start_time": "09:00", "end_time": "18:00", "hours": "12", "is_closed": false, "is_open": true }, { "day": 7, "weekday": "Sunday", "start_time": "09:00", "end_time": "18:00", "hours": "12", "is_closed": false, "is_open": true }];
const TIMING_SELECTION_INIT = { "is_open_0": true, "start_time_0": "09:00", "end_time_0": "18:00", "weekday_0": "Monday", "is_open_1": true, "start_time_1": "09:00", "end_time_1": "18:00", "weekday_1": "Tuesday", "is_open_2": true, "start_time_2": "09:00", "end_time_2": "18:00", "weekday_2": "Wednesday", "is_open_3": true, "start_time_3": "09:00", "end_time_3": "18:00", "weekday_3": "Thursday", "is_open_4": false, "start_time_4": "09:00", "end_time_4": "18:00", "weekday_4": "Friday", "is_open_5": true, "start_time_5": "09:00", "end_time_5": "18:00", "weekday_5": "Saturday", "is_open_6": true, "start_time_6": "09:00", "end_time_6": "18:00", "weekday_6": "Sunday" };
const TIMING_SELECTION_INIT_NEW = { "is_open_0": true, "start_time_0": "09:00", "end_time_0": "18:00", "weekday_0": "Monday", "day_0": "MON", "is_open_1": true, "start_time_1": "09:00", "end_time_1": "18:00", "weekday_1": "Tuesday", "day_1": "TUE", "is_open_2": true, "start_time_2": "09:00", "end_time_2": "18:00", "weekday_2": "Wednesday", "day_2": "WED", "is_open_3": true, "start_time_3": "09:00", "end_time_3": "18:00", "weekday_3": "Thursday", "day_3": "THU", "is_open_4": true, "start_time_4": "09:00", "end_time_4": "18:00", "weekday_4": "Friday", "day_4": "FRI", "is_open_5": true, "start_time_5": "09:00", "end_time_5": "18:00", "weekday_5": "Saturday", "day_5": "SAT", "is_open_6": true, "start_time_6": "09:00", "end_time_6": "18:00", "weekday_6": "Sunday", "day_6": "SUN" };
// ----------------------------------------------------------------------

const spaceLookUp = {
  meet: "Meeting Room",
  office: "Office",
  creative: "Creative"
};

const initialState = {
  isLoading: true,
  isSpaceLoading: true,
  isUploading: false,
  spaces: [],
  error: null,
  alertError: null,
  alertSuccess: null,
  venueId: null,
  branchSlug: null,
  createBranchWizardDetails: null,
  branchDetails: null,
  spaceId: null,
  spaceDetails: null,
  facilityOptions: null,
  organisation_roles: null,
  calenderLink: null,
   isLinkModalLoading: false,
  calenderLinkType: null,
  coworkInfoOptions: null,
  countries: null,
  cities: null,
  currencies: [
    'AED',
    'USD',
    'EUR',
  ],
  isCalendarOpenModal: false,
  linkCalendarSpaceId: null,
  googleId: null,
  calendarList: null,
  isBranchUpdated: false,
  branch: {
    isUpdated: false,
    general: null,
    images: null,
    facilities: [],
    cowork: null,
    drinks: [],
    emails: [],
    reportingEmails: [],
    timings: {},
    policies: null,
    schedules: [],
  },
  space: {
    general: null,
    images: null,
    amenities: [],
    timings: {},
  },
  isScheduleFetchLoading: false,
  replyTo: [],
  emailStatus: [],
  emailTypes: [],
  //venue List
  schedule: {},
  venues: [],
  pendingVenues: [],
  activeSpaceManageTabs:0
};

const slice = createSlice({
  name: 'venueOnboardCreate',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.branchDetails = null
    },

    // START SPACE LOADING
    startSpaceLoading(state) {
      state.isSpaceLoading = true;
    },

    // SET MEDIA UPLOADING
    setMediaUploadLoading(state,action) {
      state.isUploading = action.payload;
    },

    // START UPDATING
    startUpdating(state) {
      state.isBranchUpdated = true;
    },

    startLinkModalLoading(state,action) {
      state.isLinkModalLoading = true;
      const data = action.payload;
      state.calenderLinkType = data.type;
      state.linkCalendarSpaceId = data.id

    },

    stopLinkModalLoading(state) {
      state.isLinkModalLoading = false;
      state.calenderLinkType = null;
      state.linkCalendarSpaceId = null;
    },

    startFetchingSingleSchedule(state) {
      state.isScheduleFetchLoading = true
    },

    // space type for switching tab

    setActiveSpaceManageTabs(state, action){
      state.activeSpaceManageTabs = action.payload
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Display Alert Success
    setAlertSuccess(state, action) {
      const message = action.payload;
      state.alertSuccess = message;
    },

    // Display Alert Error
    setAlertError(state, action) {
      const message = action.payload;
      state.alertError = message;
    },

    // Reset Alert Success
    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    // Reset Alert Error
    resetAlertError(state) {
      state.alertError = null;
    },



    // GET VENUES LIST
    getVenuesSuccess(state, action) {
      state.isLoading = false;
      state.venues = action.payload;
    },



    // GET VENUES LIST
    getPendingVenuesSuccess(state, action) {
      state.isLoading = false;
      state.pendingVenues = action.payload;
    },
    // GET SPACE LIST
    getSpacesSuccess(state, action) {
      state.isSpaceLoading = false;
      const { data } = action.payload;
      state.spaces = data;
    },

    // STORE TEMP BRANCH CREATE WIZARD DETAILS
    storeCreateBranchWizardDetails(state, action) {
      const details = action.payload;

      const updatedDetails = {
        ...(state.createBranchWizardDetails || {}),
        ...details,
      }
      state.createBranchWizardDetails = updatedDetails;
    },

    // GET BRANCH DETAILS
    getBranchDetailsSuccess(state, action) {
      const res = action.payload.data ? action.payload.data : action.payload;
      state.isDetailsLoading = false;
      // console.log(JSON.stringify(res, null,2));
      state.venueId = res?.general?.id
      state.branchDetails = res;
    },

    // GET FACILITY OPTIONS
    getSupportOptionsSuccess(state, action) {
      state.isDetailsLoading = false;
      const data = action.payload.data ? action.payload.data : action.payload;
      state.facilityOptions = data?.facilities || []
      state.organisation_roles = data?.organisation_roles || []
      state.currencies = (data?.currencies || []).sort((a, b) => {
        if (a?.value < b?.value) return -1;
        if (a?.value > b?.value) return 1;
        return 0;
      });
      state.coworkInfoOptions = {
        ambience: data?.ambiences || [],
        good_for: data?.good_for || [],
        review_badges: data?.review_badges || [],
        letswork_perks: data?.letswork_perks || [],
        freedrinks: data?.recommended_bites || [],
        venue_types: data?.venue_types || [],
        amenities: data?.amenities || [],
      }
    },

    getBulkEmailSupportOptionsSuccess(state, action) {
      state.isDetailsLoading = false;
      const data = action.payload.data ? action.payload.data : action.payload;
      state.emailTypes = data?.email_types || []
      state.emailStatus = data?.email_status || []
      state.replyTo = data?.reply_to || []

    },

    // GET COUNTRY OPTIONS
    getCountryOptionsSuccess(state, action) {
      state.isDetailsLoading = false;
      const data = action.payload.data ? action.payload.data : action.payload;
      state.countries = data && data || [];
      console.log("contry list data from redux", JSON.stringify(data, null, 2))
    },

    // GET FACILITY OPTIONS
    getFacilityOptionsSuccess(state, action) {
      state.isDetailsLoading = false;
      state.facilityOptions = action.payload.context ? action.payload.context : action.payload;
    },

    // GET COWORK INFO OPTIONS
    getCoworkInfoOptionsSuccess(state, action) {
      state.isDetailsLoading = false;
      state.coworkInfoOptions = action.payload.context ? action.payload.context : action.payload;
    },

    // GET COUNTRIES OPTIONS
    getCountriesSuccess(state, action) {
      state.isDetailsLoading = false;
      const countryResponse = action.payload.context ? action.payload.context : action.payload;
      state.countries = countryResponse && countryResponse.sort((country1, country2) => country1.id - country2.id) || null;
    },

    // GET CITIES OPTIONS
    getCitiesSuccess(state, action) {
      state.isDetailsLoading = false;
      console.log(action.payload);
      state.cities = action.payload ? action.payload : [];
    },

    // GET SPACE DETAILS
    getSpaceDetailsSuccess(state, action) {
      state.isDetailsLoading = false;
      state.branchDetails = action.payload;
    },

    // SET DEFAULTS
    setBranchFormDefaults(state, action) {
      const details = action.payload && action.payload.data ? action.payload.data : action.payload;
      const general = {
        name: details?.general?.name || '',
        phone_number: details?.general?.phone_number || '',
        primary_contact_email: details?.general?.primary_contact_email || '',
        manager_name: details?.general?.manager_name || '',
        latitude: details?.general?.latitude || '',
        longitude: details?.general?.longitude || '',
        country: details?.general?.city?.country?.id || '',
        city: details?.general?.city?.id || '',
        website_url: details?.general?.website_url || '',
        is_meeting_room: details?.general ? details?.general.is_meeting_room : true,
        is_office_room: details?.general ? details?.general.is_office_room : true,
        is_creative_studio: details?.general ? details?.general.is_creative_studio : true,
        is_cowork: details?.general ? details?.general.is_cowork : false,
        venue_type: details?.general && details?.general.venue_type || '',
        address: details?.general?.address || '',
        description: details?.general?.description || '',
        currency_code: details?.general?.currency_code || '',
      };
      general.id = details?.general?.id || 0;
      // console.log(general);

      const images = details?.media || [];
      // console.log(images);

      const facilities = details?.facilities || [];
      // console.log(facilities);

      const goodFor = details && details?.cowork_settings?.good_for ? details.cowork_settings?.good_for.map(item => item.id) : [];

      const reviewBadges = details?.cowork_settings?.review_badges?.map(badge => +badge.id) || [];

      const cowork = {
        goodFor: details?.cowork_settings?.good_for.map(({ id, name, ...rest }) => {
          return { id, name };
        }) || [],
        reviewBadges,
        ambience: details && details?.cowork_settings?.ambience || '',
        capacity: details?.cowork_settings?.capacity || 0,
        wifi_name: details && details?.cowork_settings?.wifi_name || '',
        wifi_password: details && details?.cowork_settings?.wifi_password || '',
        first_free_checkin: details && details?.cowork_settings?.first_free_checkin || false,
        drinks: details?.cowork_settings?.drinks.map(({ id, name, image: icon, ...rest }) => {
          return { id, name, icon };
        }) || [],
        amenities: details?.cowork_settings?.amenities?.map(({ id, name, icon, ...rest }) => {
          return { id, name, icon };
        }) || [],
        schedule_time_set: details?.cowork_settings?.schedule?.id,
        annually_price: details?.cowork_settings?.annually_price,
        monthly_price: details?.cowork_settings?.monthly_price,
      }

      const drinks = details?.cowork_settings?.drinks.map(drink => drink?.id?.toString()) || [];


      const emails = details && details?.notification?.venue_notify_emails?.length ? details.notification?.venue_notify_emails : [];
      // console.log(emails);

      const reportingEmails = details && details.notification?.venue_reporting_emails?.length ? details.notification?.venue_reporting_emails : [];

      const timings = details && details.timings?.reduce((previousValue, currentValue, currentIndex) => {
        const item = {};
        item[`is_open_${currentIndex}`] = !currentValue.is_closed;
        item[`weekday_${currentIndex}`] = currentValue.weekday;
        item[`start_time_${currentIndex}`] = currentValue.start_time?.slice(0, 5) || '09:00';
        item[`end_time_${currentIndex}`] = currentValue.end_time?.slice(0, 5) || '18:00';
        return {
          ...previousValue,
          ...item,
        };
      }, {}) || Object.assign(TIMING_SELECTION_INIT, {});
      const policies = {
        space_cancellation_policy: details?.policies?.space_cancellation_policy || '',
        space_checkin_instructions: details?.policies?.space_checkin_instructions || '',
        food_policy: details?.policies?.food_policy || '',
        cowork_checkin_instructions: details?.policies?.cowork_checkin_instructions || '',
        terms_and_conditions: details?.policies?.terms_and_conditions || '',
      };

      const schedules = [
        { "name": "Schedule Anee Test 3", "description": "test3", "is_default": true, "id": 8 },
      ]

      state.branchSlug = details?.general?.slug || '';
      state.venueId = details?.general?.id || 0;
      state.branch.general = general;
      state.branch.images = images;
      state.branch.facilities = facilities;
      state.branch.cowork = cowork;
      state.branch.drinks = drinks;
      state.branch.emails = emails;
      state.branch.reportingEmails = reportingEmails;
      state.branch.schedules = details?.schedules || []; // || schedules
      state.branch.progress = details?.progress || 0;
      state.branch.timings = timings;
      state.branch.policies = policies;
      state.branch.letsWorkSettings = details?.letswork_settings;
      state.branch.VenueSettings = details?.venue_settings;
      state.branch.currency_code = details?.general?.currency_code;
      // Init timing when NEW venue
      // if(details && details.branch_timings && !details.branch_timings.length){
      //   state.branchDetails.branch_timings = Object.assign(DEFAULT_TIMING_LIST,{});
      // }
      state.isLoading = false;
      state.createBranchWizardDetails = null; // reset wizard data
    },

    // CREATE BRANCH DETAILS
    createEditBranchSuccess(state, action) {
      const data = action.payload && action.payload.data ? action.payload.data : action.payload;
      state.branchSlug = data?.general?.slug;
      state.venueId = data?.general?.id;
    },

    // SET SINGLE SCHEDULE DEFAULT
    setSingleScheduleDefaults(state, action) {
      const details = action.payload && action.payload.data ? action.payload.data : action.payload;

      const scheduleTimings = details && details.line_items?.reduce((previousValue, currentValue, currentIndex) => {
        const item = {};
        item[`is_open_${currentIndex}`] = currentValue?.status_enum === 'OPEN';
        item[`day_${currentIndex}`] = currentValue?.day_enum;
        item[`start_time_${currentIndex}`] = currentValue.start_time?.slice(0, 5) || '09:00';
        item[`end_time_${currentIndex}`] = currentValue.end_time?.slice(0, 5) || '18:00';
        return {
          ...previousValue,
          ...item,
        };
      }, {}) || Object.assign(TIMING_SELECTION_INIT_NEW, {});
      const scheduleDefault = {
        "name": details?.name || '',
        "description": details?.description || '',
        "is_default": details?.is_default || false,
        "line_items": scheduleTimings,
        "id": details?.id
      }
      state.schedule = scheduleDefault;
      state.isScheduleFetchLoading = false;
    },

    // CREATE BRANCH DETAILS
    createEditSingleScheduleSuccess(state, action) {
      const data = action.payload && action.payload.data ? action.payload.data : action.payload;
      state.branchSlug = data?.general?.slug;
      state.venueId = data?.general?.id;
      state.schedule = {};
    },

    addDrinkSuccess(state, action) {
      const context = action.payload && action.payload.context ? action.payload.context : action.payload;
      console.log('state before adding drinks', state.drinks);
      state.drinks = context.bite_id.split(',');
      console.log('new state after adding drinks', state.drinks);
    },

    updateMediaSuccess(state) {
      // const imageId = action.payload;
      const imageUpdatedIndex = -1;
      // const filteredItems = state.branch.images?.filter((_file, index) => {
      //   const result = _file.id !== imageId;
      //   if (!result) {
      //     imageUpdatedIndex = index;
      //   }
      //   return result;
      // });
      if (imageUpdatedIndex > -1) state.branch.images[imageUpdatedIndex].is_front = 1;
    },

    updateVenueSettings(state,action) {
      state.branch.VenueSettings = action.payload;
    },

    getCalendarListSuccess(state, action) {
      const data = action.payload;
      const link = data?.length ? data[0] : null
      state.calenderLink = link;

    },

    // BRANCH UPDATED - REFETCHed DETAILS
    branchUpdateSuccess(state) {
      state.isBranchUpdated = false;
    },

    // CLOSE MODAL
    openCalendarModal(state,action) {
      state.isCalendarOpenModal = true;
      state.calenderLinkType = action.payload
    },

    // CLOSE MODAL
    closeCalendarModal(state) {
      state.isCalendarOpenModal = false;
      state.calenderLinkType = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setBranchFormDefaults, setSingleScheduleDefaults,setAlertError, resetAlertSuccess, resetAlertError, storeCreateBranchWizardDetails,setActiveSpaceManageTabs, openCalendarModal,
  closeCalendarModal ,updateVenueSettings} = slice.actions;


// ----------------------------------------------------------------------

export function getVenues(org_id) {
  return async () => {
    dispatch(slice.actions.hasError());
    dispatch(slice.actions.setAlertError());
    dispatch(slice.actions.startLoading());
    try {
      let isValid = org_id != undefined && org_id != '' && org_id != null
      const response = await axios.get(isValid ? URL_PLACES.venues.replace(":org_id", org_id) : URL_PLACES.adminVenues);
      if (response.data.status) {
        dispatch(slice.actions.getVenuesSuccess(response.data.data));
      } else {
        // dispatch(slice.actions.hasError(response.data.error || B_FETCH_ERROR));
        // dispatch(slice.actions.setAlertError(response.data.error || B_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.getVenuesSuccess([]));
      dispatch(slice.actions.hasError(B_FETCH_ERROR));
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------

export function getPendingVenues() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_PLACES.pendingVenues);
      if (response.data.status) {
        dispatch(slice.actions.getPendingVenuesSuccess(response.data.data));
      } else {
        dispatch(slice.actions.hasError(response.data.error || B_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error || B_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.getPendingVenuesSuccess([]));
      dispatch(slice.actions.hasError(B_FETCH_ERROR));
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}

export function getSpaces(venueId, currentTab, org_id) {
  return async () => {
    dispatch(slice.actions.startSpaceLoading());
    const API_URL = `${(`${URL_PLACES.allSpaces}`).replace(":org_id", org_id).replace(':venue_id', venueId)}`;
    try {
      const response = await axios.get(API_URL, {
        // params: { 
        //   venue_id: venueId,
        //   space_type: spaceLookUp[currentTab],
        // },
      });
      if (response.data.success) {
        dispatch(slice.actions.getSpacesSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data.message));
        dispatch(slice.actions.setAlertError(response.data.message));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(B_FETCH_ERROR));
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function refreshBranchDetails(venueId, org_id) {
  console.log('refreshBranchDetails')
  return async () => {
    try {
      dispatch(slice.actions.branchUpdateSuccess());
      const response = await axios.get(URL_PLACES.venueDetails.replace(":org_id", org_id).replace(":venue_id", venueId));
      if (response.data.status) {
        const getData = response.data.data;
        dispatch(slice.actions.getBranchDetailsSuccess(getData));
        dispatch(slice.actions.setBranchFormDefaults(getData));
        // dispatch(slice.actions.branchUpdateSuccess());
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error || B_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(B_DETAILS_LOAD_ERROR));
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}

// ----------------------------------------------------------------------

export function getBranchDetails(venueId, org_id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_PLACES.venueDetails.replace(":org_id", org_id).replace(":venue_id", venueId));
      if (response.data.success) {
        const getData = response.data.data;
        dispatch(slice.actions.getBranchDetailsSuccess(getData));
        dispatch(slice.actions.setBranchFormDefaults(getData));
      } else {
        dispatch(slice.actions.hasError(B_DETAILS_FETCH_ERROR));
        dispatch(
          slice.actions.setAlertError(
            response.data.error || B_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      console.log(error, "error");
      dispatch(slice.actions.hasError(B_DETAILS_LOAD_ERROR));
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------

export function getSupportOptions() {
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.get(URL_PLACES.supportOptions);
      if (response.data.success) {
        dispatch(slice.actions.getSupportOptionsSuccess(response.data.data));
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error || SUPPORT_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}


export function getBulkEmailSupportOptions() {
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.get(URL_PLACES.bulkEmailSupportOptions);
      console.log(response.data,"getBulkEmailSupportOptions")
      if (response.data.success) {
        dispatch(slice.actions.getBulkEmailSupportOptionsSuccess(response.data.data));
      } else {
        dispatch(
            slice.actions.setAlertError(
                response.data.error || SUPPORT_DETAILS_FETCH_ERROR
            )
        );
      }
    } catch (error) {
      console.log(error,"error in getBulkEmailSupportOptions")
      // Extract all error keys and append messages together
      const errorData = error?.response?.data?.error;
      const message = error?.response?.data?.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------

export function getCountryOptions() {
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.get(URL_PLACES.countryOptions);
      console.log(response, "response");
      if (response.data.status) {
        dispatch(slice.actions.getCountryOptionsSuccess(response.data.data));
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.message || COUNTRY_LIST_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------

export function getCountries() {
  return async () => {
    try {
      const response = await axios.get(URL_PLACES.countries);
      if (response.data.status) {
        dispatch(slice.actions.getCountriesSuccess(response.data.context));
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || CON_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  }
}
// ----------------------------------------------------------------------

export function getCities(id) {
  return async () => {
    try {
      const response = await axios.get(URL_PLACES.cityOptions.replace(":id", id));
      if (response.data.status) {
        console.log(response.data.data.cities, "response.data.data");
        dispatch(slice.actions.getCitiesSuccess(response?.data?.data?.cities));
        return response?.data?.data?.cities
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || CITY_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  }
}
// ----------------------------------------------------------------------

export function getSingleSchedule(venueId, scheduleId, org_id) {
  const API_URL = `${(`${URL_PLACES.schedules}`).replace(":org_id", org_id).replace(':venue_id', venueId)}${scheduleId ? `${scheduleId}/` : ''}`;
  return async () => {
    dispatch(slice.actions.startFetchingSingleSchedule());
    try {
      const response = await axios.get(API_URL);
      // const response ={ data: { status: 1, context: { venue_id: 591} }} ;
      if (response.data.success) {
        dispatch(slice.actions.setSingleScheduleDefaults(response.data.data));


      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || CREATE_EDIT_ERROR) // // TODO: to remove error.Error.
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------
//              SAVE
// ----------------------------------------------------------------------

export function createEditBranch(details, orgId,setState) {
  console.log("createEditBranch..", details && JSON.stringify(details, null, 2))
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.post(URL_PLACES.onboardVenue.replace(":org_id", orgId), details);
      // const response ={ data: { status: 1, context: { venue_id: 591} }} ;
      if (response.data.success) {
        const getData = response.data.data;
        if (getData?.general?.id && !details?.general?.id) {

          dispatch(slice.actions.createEditBranchSuccess(getData));
          dispatch(slice.actions.setAlertSuccess("Venue Created" || response?.data?.message));
          dispatch(slice.actions.startUpdating());
        } else {
          dispatch(slice.actions.setAlertSuccess("Venue Updated" || response?.data?.message));
          dispatch(slice.actions.startUpdating());
        }


      } else {
        const errorData = response.data.error;
        const message = response.data.message;
        let combinedMessage = '';

        // Iterate through each key in the error data
        for (const key in errorData) {
          if (errorData.hasOwnProperty(key)) {
            const value = errorData[key];

            // Check if the value is an array
            if (Array.isArray(value)) {
              combinedMessage += `${key}: ${value.join(', ')} `;
            } else {
              combinedMessage += `${key}: ${value} `;
            }
          }
        }
        dispatch(
          slice.actions.setAlertError(message + " " +combinedMessage || CREATE_EDIT_ERROR) // // TODO: to remove error.Error.
        );
      }
    } catch (error) {
      console.log(error);
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }finally {
      // Check if setState function exists, then set it to false
      if (setState) {
        setState(false);
      }
    }
  };
}
// ----------------------------------------------------------------------


export function updateLetsWorkSettings(details, screen, venueId, org_id,setIsLoading) {
  console.log("createEditBranch..", details)
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.put(URL_PLACES.letsWorkSettings.replace(":org_id", org_id).replace(':venue_id', details.id || venueId), details);
      // const response ={ data: { status: 1, context: { venue_id: 591} }} ;
      if (response.data.success) {
        if (response.data.data?.id || !details.id) {
          dispatch(slice.actions.createEditBranchSuccess(response.data.data));
          dispatch(slice.actions.setAlertSuccess(response?.data?.message));
          dispatch(slice.actions.startUpdating());
        } else {
          // dispatch(slice.actions.setAlertSuccess(response?.data?.message));
          dispatch(slice.actions.startUpdating());
        }


      } else {
        // Extract all error keys and append messages together
        console.log(response,"errorData")
        const errorData = response.data.error;
        console.log(errorData,"errorData")
        const message = response.data.message;
        console.log(message,"message")
        let combinedMessage = '';

        // Iterate through each key in the error data
        for (const key in errorData) {
          if (errorData.hasOwnProperty(key)) {
            const value = errorData[key];

            // Check if the value is an array
            if (Array.isArray(value)) {
              combinedMessage += `${key}: ${value.join(', ')} `;
            } else {
              combinedMessage += `${key}: ${value} `;
            }
          }
        }
        dispatch(
          slice.actions.setAlertError(message + " " +combinedMessage || CREATE_EDIT_ERROR) // // TODO: to remove error.Error.
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      console.log(errorData,"errorData")
      const message = error.response.data.message;
      console.log(message,"message")
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }finally {
      if(setIsLoading){
        setIsLoading(false)
      }
    }
  };
}
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export function updateVenueAdvanceDetails(details, screen, venueId, org_id) {
  console.log("createEditBranch..", details)
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.put(URL_PLACES.createVenue.replace(':org_id', org_id).replace(':venue_id', details.id || venueId), details,
        {},

      );
      // const response ={ data: { status: 1, context: { venue_id: 591} }} ;
      if (response.data.success) {
        if (response.data.data?.id || !details.id) {
          dispatch(slice.actions.createEditBranchSuccess(response.data.data));
          dispatch(slice.actions.setAlertSuccess(response?.data?.message));
          dispatch(slice.actions.startUpdating());
        } else {
          dispatch(slice.actions.setAlertSuccess(response?.data?.message));
          dispatch(slice.actions.startUpdating());
        }


      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || CREATE_EDIT_ERROR) // // TODO: to remove error.Error.
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------

export function createEditSingleSchedule(details, screen, venueId, scheduleId, org_id) {
  console.log("createEditSingleSchedule..", details)
  const API_URL = `${(`${URL_PLACES.schedules}`).replace(":org_id", org_id).replace(':venue_id', venueId)}${scheduleId ? `${scheduleId}/` : ''}`;
  return async () => {
    try {
      // const response = await axios.post(URL_PLACES.createVenue,
      const response = scheduleId ? await axios.put(API_URL, details) : await axios.post(API_URL, details);
      // const response ={ data: { status: 1, context: { venue_id: 591} }} ;
      if (response.data.success) {
        dispatch(slice.actions.createEditSingleScheduleSuccess(response.data.data));
        dispatch(slice.actions.setAlertSuccess(response?.data?.message));
        dispatch(slice.actions.startUpdating());


      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || CREATE_EDIT_ERROR) // // TODO: to remove error.Error.
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------

export function deleteSingleSchedule(venueId, scheduleId, org_id) {
  console.log("deleteSingleSchedule..", venueId, scheduleId)
  const API_URL = `${(`${URL_PLACES.schedules}`).replace(":org_id", org_id).replace(':venue_id', venueId)}${scheduleId ? `${scheduleId}/` : ''}`;
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      // const response = await axios.post(URL_PLACES.createVenue, 
      const response = await axios.delete(API_URL);
      // const response ={ data: { status: 1, context: { venue_id: 591} }} ;
      if (response?.status === 204) {
        dispatch(slice.actions.setAlertSuccess(response?.data?.message));
        dispatch(slice.actions.startUpdating());

      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error || response?.data?.message) // // TODO: to remove error.Error.
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------

export function updateCoworkInfo(venueId, details) {
  return async () => {
    try {
      const response = await axios.post(URL_PLACES.createBranch, details);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(C_UPDATE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || C_UPDATE_ERROR)
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------

export function uploadMedia(file, venueId, org_id,onProgress) {
  return async () => {
    try {
      dispatch(slice.actions.setMediaUploadLoading(true))
      const formData = new FormData();
      formData.append('image', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        params: {
          venue_id: venueId,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgress(progress);  // Update progress
        },
      };
      const response = await axios.post(URL_PLACES.venueMedia.replace(":org_id", org_id).replace(":venue_id", venueId), formData, config);
      if (response.data.success) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_UPLOAD_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.message || response.data.title || M_UPLOAD_ERROR)
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }finally {
      dispatch(slice.actions.setMediaUploadLoading(false))
    }
  };
}
// ----------------------------------------------------------------------

export function removeMedia(id, venueId, org_id) {
  return async () => {
    try {
      const response = await axios.delete(URL_PLACES.venueMedia.replace(":org_id", org_id).replace(":venue_id", venueId) + `${id}/`, {});
      console.log(response, "response");
      if (response.status == 204) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_DELETE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.message || response.data.title || M_DELETE_ERROR)
        );
      }
    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------

export function toggleIsFrontMedia(id, venueId, org_id) {
  return async () => {
    try {
      const putData = { is_front: true };
      const response = await axios.put(URL_PLACES.venueMedia.replace(":org_id", org_id).replace(":venue_id", venueId) + `${id}/`, putData, {
        params: { media_id: id, venue_id: venueId },
      });
      console.log(response, "Innn");
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_ISFRONT_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.message || response.data.title || M_ISFRONT_ERROR)
        );
      }
    } catch (error) {
      console.log(error, "outt");
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }
  };
}
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getGoogleCalendarList(branchId,type) {
  return async () => {
    try {
      const selectedType = type === CALENDER_TYPE.GOOGLE ? "google" : "outlook"
      dispatch(slice.actions.startLinkModalLoading({type,id:branchId}))
      const response = await axios.post( URL_PLACES.calendarList.replace(":id",branchId).replace(":type",selectedType) );
      if(response.data.success){
        dispatch(slice.actions.getCalendarListSuccess(response.data.data));
        const link = Array.isArray(response.data.data) ? response.data.data[0] : response.data.data;

         return  link

      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || CALENDAR_FETCH_ERROR));
      }

    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }finally {
      dispatch(slice.actions.stopLinkModalLoading())
    }
  };
}
// ----------------------------------------------------------------------

export function syncGoogleCalendar(branchId,type) {
  return async () => {
    try {
      const selectedType = type === CALENDER_TYPE.GOOGLE ? "google" : "outlook"
      dispatch(slice.actions.startLinkModalLoading({type,id:branchId}))
      const response = await axios.post( URL_PLACES.googleCalendarSync.replace(":id",branchId) );
      if(response.data.success){
        dispatch(slice.actions.setAlertSuccess(response?.data?.message));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || CALENDAR_FETCH_ERROR));
      }

    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }finally {
      dispatch(slice.actions.stopLinkModalLoading())
    }
  };
}

export function syncOutLookCalendar(spaceId,branchId,type) {
  return async () => {
    try {
      dispatch(slice.actions.startLinkModalLoading({type,id:branchId}))
      const response = await axios.post( URL_PLACES.outlookCalendarSync.replace(":venue_id",branchId).replace(":space_id",spaceId));
      if(response.data.success){
        dispatch(slice.actions.setAlertSuccess(response?.data?.message));
      } else {
        dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || CALENDAR_FETCH_ERROR));
      }

    } catch (error) {
      // Extract all error keys and append messages together
      const errorData = error.response.data.error;
      const message = error.response.data.message;
      let combinedMessage = '';

      // Iterate through each key in the error data
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          const value = errorData[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
            combinedMessage += `${key}: ${value.join(', ')} `;
          } else {
            combinedMessage += `${key}: ${value} `;
          }
        }
      }
      dispatch(slice.actions.setAlertError(message + " " +combinedMessage));
    }finally {
      dispatch(slice.actions.stopLinkModalLoading())
    }
  };
}

export function linkCalendarSave(calendarId, calendarName, spaceId) {
  // {"space_id":"55","calendar_id":"c_vlvs605f06j6it4j0ada7n3op8@group.calendar.google.com","calendar_name":"Dow 6"}
  return async () => {
    console.log('linkCalendarSave>>>> API_URL::',calendarId, calendarName, spaceId);
    // try {
    //   const postData = {};
    //   postData.space_id = spaceId.toString();
    //   postData.calendar_id = calendarId;
    //   postData.calendar_name = calendarName;
    //   const response = await axios.post( URL_PLACES.linkCalendar, postData);
    //   if(response.data.status){
    //     dispatch(slice.actions.closeCalendarModal());
    //     dispatch(slice.actions.setAlertSuccess(CALENDAR_LINK_SUCCESS));
    //   } else {
    //     dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || CALENDAR_LINK_ERROR));
    //   }
    //
    // } catch (error) {
    //   dispatch(slice.actions.setAlertError(CALENDAR_LINK_ERROR));
    // }
  };
}
// ----------------------------------------------------------------------