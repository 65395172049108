// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
// hooks
// config
import {ROLE} from "../../../utils/constants";

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  venue: getIcon('ic_venue_up'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  settings: getIcon('ic_settings'),
};


const conditionalConfig = {
  calendar: { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  overview:{ title: 'overview', path: PATH_DASHBOARD.dashboard.overview, icon: ICONS.dashboard },
      venues: {
        BOTH: {
          title: 'Venues',
          path: PATH_DASHBOARD.venues.root,
          icon: ICONS.venue,
          children: [
            {title: 'list', path: PATH_DASHBOARD.venues.list},
            {title: 'notifications', path: PATH_DASHBOARD.venues.emailNotification},
          ],
        },
        ALL: {
          title: 'Venues',
          path: PATH_DASHBOARD.venues.list,
          icon: ICONS.venue,
        },
      },
  sales: {
    BOTH: [{
      title: 'Sales',
      path: PATH_DASHBOARD.sales.root,
      icon: ICONS.ecommerce,
      children: [
        { title: 'bookings', path: PATH_DASHBOARD.sales.bookings },
        { title: 'coworking', path: PATH_DASHBOARD.sales.coworking },
      ],
    }],
    BOOK: [{ title: 'Sales', path: PATH_DASHBOARD.sales.bookings, icon: ICONS.ecommerce }],
    WORK: [{ title: 'Sales', path: PATH_DASHBOARD.sales.coworking, icon: ICONS.ecommerce }],
  },
  customers: {
    BOTH: {
      title: 'Customers',
      path: PATH_DASHBOARD.customers.root,
      icon: ICONS.user,
      children: [
        { title: 'bookings', path: PATH_DASHBOARD.customers.bookings },
        { title: 'coworking', path: PATH_DASHBOARD.customers.coworking },
        { title: 'reviews', path: PATH_DASHBOARD.customers.reviews },
        // { title: 'checkin', path: PATH_DASHBOARD.customers.checkin },
      ],
    },
    BOOK: { title: 'Customers', path: PATH_DASHBOARD.customers.bookings, icon: ICONS.user },
    WORK: { title: 'Customers', path: PATH_DASHBOARD.customers.root, icon: ICONS.user , children: [
        { title: 'coworking', path: PATH_DASHBOARD.customers.coworking },
        { title: 'reviews', path: PATH_DASHBOARD.customers.reviews },
      ], }
  },
  pending:{ title: 'pending', path: PATH_DASHBOARD.pending, icon: ICONS.banking },
  settings: { title: 'Settings', path: PATH_DASHBOARD.settings, icon: ICONS.settings },
  organization: { title: 'organization', path: PATH_DASHBOARD.organisation.list, icon: ICONS.banking },
};


const navConfig = () => [
  // DASHBOARD
  // ----------------------------------------------------------------------
  {
    subheader: ' ',
    items: [
      // { title: 'overview', path: PATH_DASHBOARD.dashboard.overview, icon: ICONS.dashboard },
      ...(GetUserRoleBasedRouteConfig()),
      // { title: 'venues', path: PATH_DASHBOARD.venues.list, icon: ICONS.venue },
      // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      // {
      //   title: 'customers',
      //   path: PATH_DASHBOARD.customers.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'bookings', path: PATH_DASHBOARD.customers.bookings },
      //     { title: 'coworking', path: PATH_DASHBOARD.customers.coworking },
      //   ],
      // },

    ],
  },
];

export default navConfig;

function GetUserRoleBasedRouteConfig() {
  const userType = window.localStorage.getItem('lwt_user');
  const systemUser = JSON.parse(userType)?.system_user;
  const orgRole = JSON.parse(userType)?.organisation_user_details?.role_type;

  //SUPER ADMIN USER  &&   //CUSTOMER SUPPORT USER
  if(systemUser == ROLE.SUPER_USER || systemUser == ROLE.CUSTOMER_SUPPORT  ){
    return [
      conditionalConfig.overview,
      conditionalConfig.organization,
      conditionalConfig.calendar,
      conditionalConfig.venues.BOTH,
      conditionalConfig.customers.BOTH,
      conditionalConfig.pending
    ]
  }


  //ORGANIZATION OWNER USER

  if(systemUser == ROLE.USER && orgRole == ROLE.ORGANISATION_OWNER   ){
    return [
      conditionalConfig.overview,
      conditionalConfig.organization,
      conditionalConfig.calendar,
      conditionalConfig.venues.ALL,
      conditionalConfig.customers.BOTH,
      conditionalConfig.settings,
    ]
  }

  // VENUE MANAGER
  if(systemUser == ROLE.USER && orgRole == ROLE.VENUE_MANAGER   ){
    return [
      conditionalConfig.overview,
      conditionalConfig.calendar,
      conditionalConfig.venues.ALL,
      conditionalConfig.customers.BOTH,
    ]
  }

  // COWORK MANAGER
  if(systemUser == ROLE.USER && orgRole == ROLE.COWORK_MANAGER   ){
    return [
      conditionalConfig.overview,
      conditionalConfig.venues.ALL,
      conditionalConfig.customers.WORK,
      // conditionalConfig.sales.WORK
    ]
  }

  // MEET MANAGER
  if(systemUser == ROLE.USER && orgRole == ROLE.MEET_MANAGER   ){
    return [
      conditionalConfig.overview,
      conditionalConfig.calendar,
      conditionalConfig.venues.ALL,
      conditionalConfig.customers.BOOK,
      // conditionalConfig.sales.BOOK
    ]
  }


  return [];

}
